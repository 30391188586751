import React from 'react'
import './aboutSec.css'

import About_Img from '../aboutSection/images/ab.webp'


function AboutSection(){
    return(
        <div className='container section_padding'>
            <div className="row w-100">
                <div className="col-md-6"> 
                    <div className="ab_content">
                        <h2 className='banner_text titleFont_style'>About Us</h2>
                        <p className='text mt-3'>We dedicate special attention to curating bespoke services so that our clients can enjoy uncompromised luxury, ease of operation, safety and discretion.
                            Our team is ready to assist you to make your dreams into a reality.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center">
                    <div className="abSide_img">
                        <img src={About_Img} alt="" className='w-100' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;