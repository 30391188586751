import React from 'react'

import {Swiper , SwiperSlide} from "swiper/react"

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import './clientSection.css'


// import C1 from '../homePageAssets/c1.webp'
import AllState from './images/AS-removebg.webp'
import Farmer_insu from './images/FI-n-removebg.webp'
import Progressive_insu from './images/Prog-removebg.webp'
import State_insu from './images/SF-removebg.webp'

// import required modules
import { Pagination } from 'swiper'

export default function App() {
  return (
    <> 
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
       <div className="py-5 d-flex align-items-center">
          <SwiperSlide> <div className=" client_logo my-5"> <a href='' target="_blank" ><img src={State_insu} className='w-100 pt-3' alt="" /></a></div> </SwiperSlide>
          <SwiperSlide> <div className=" client_logo my-5"> <a href='' target="_blank" ><img src={AllState} className='w-100' alt="" /></a></div> </SwiperSlide>
          <SwiperSlide> <div className=" client_logo my-5"> <a href=''target="_blank" ><img src={Progressive_insu} className='w-100' alt="" /></a></div> </SwiperSlide>
          <SwiperSlide> <div className=" client_logo my-5"> <a href=''target="_blank" ><img src={Farmer_insu} className='w-100' alt="" /></a></div> </SwiperSlide>
          
       </div>

      </Swiper>
    </>
  );
}
