import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Header from './Components/header/header';
import Footer from './Components/footer/footer';
import Home from './Components/homePage/home';
import About_Us from './Components/aboutUs/all';

import ServicesPage from './Components/servicesPage/servicesPage';
// import Pricing from './Components/Pricing/pricing';
import Contact from './Components/Contact/contact';

function App() {
  return (
    <div className="App">
      <Header />

      <Router >
        <Routes>
          <Route path = '/' element = {<Home />} />
          <Route path = '/aboutUs' element = {<About_Us />} />
          <Route path = '/services' element = {<ServicesPage />}   />
          <Route path = '/contact' element = { <Contact />}  />
          {/* <Route path = '/pricing' element = { <Pricing />}  /> */}

        </Routes>
      </Router>

      <Footer />

    </div>
  );
}

export default App;
