import React from "react";
import './servicesSection.css'

import Auto_insu_Leads from './images/Auto_insurance-removebg.png';
import Home_insu_Leads from './images/homeInsurance-removebg.png'

function ServicesSection(){
    return(
        <div className="container section_padding text-center">  

            <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <span className='span_Titles' ><b>What We’re Offering</b></span>
                <h2 className='banner_text titleFont_style'>Our Services</h2>
            </div>

                        {/* SERVICES */}

            <div className="row py-5  home_service_row g-5">
                <div className="col-md-7 d-flex flex-column justify-content-center">
                    <h2 className='sub_titles text-start'>Auto Insurance Leads</h2>
                    <p className="text text-start">
                        We understand how challenging and costly it can be to find qualified auto insurance leads. It’s equally frustrating and expensive to lose potential clients to competing agents. However, our proven lead generation techniques make it easier to identify and secure high-quality auto insurance leads. Not only do we deliver leads, but we also ensure they’re tailored to meet your needs.
                    </p>

                    <p className="text text-start">
                        We have demonstrated our value to national companies, including Allstate, Farmers, and Nationwide, among others. Every day, these agents grow their books of business, one exclusive lead and one policy at a time. Join the ranks of successful insurance agencies and start acquiring exclusive auto leads today!
                    </p>
                    
                </div>
                <div className="col-md-5 d-flex justify-content-end">
                    <div className="s_imgs s_img_mob">
                        <img src={Auto_insu_Leads} className="w-100" alt="" />
                    </div>
                </div>
            </div>

            <div className="row py-5 g-5">
                <div className="col-md-5 d-flex justify-content-start">
                    <div className="s_imgs s_img_mob">
                        <img src={Home_insu_Leads} className="w-100" alt="" />
                    </div>
                </div>

                <div className="col-md-7 d-flex flex-column justify-content-center">
                    <h2 className='sub_titles text-start'>Home Insurance Leads</h2>
                    <p className="text text-start">
                        Homeowner leads are among the most valuable in the insurance industry. A homeowner prospect opens the door to discussions about bundling additional coverage, such as auto insurance. If you sell life insurance, it’s another great opportunity to expand your offerings. Instead of losing these valuable leads to competitors, Lead Ventures delivers 100% exclusive, double-verified homeowner leads directly to you.
                    </p>
                    <p className="text text-start">
                        Homeowner leads are among the most valuable in the insurance industry. A homeowner prospect opens the door to discussions about bundling additional coverage, such as auto insurance. If you sell life insurance, it’s another great opportunity to expand your offerings. Instead of losing these valuable leads to competitors, Lead Ventures delivers 100% exclusive, double-verified homeowner leads directly to you.
                    </p>
                </div>

                
                
            </div>

            <a href="/services" className="btn blue_Btn text-light rounded">View All Services<i class="fa-solid fa-arrow-right ms-2"></i> </a> 
        </div>
    )
}

export default ServicesSection;