import React from "react";
// import Address from "./officeAddress/address";
import Form from "./form/form";
import PageBanner from "./pageBanner/pageBanner";
// import Meeting from "./meeting/meeting";
// import ContactInfo from "./contactInfo/contactInfo";
// import LocationMap from "./officeAddress/map/map";

function Contact(){
    return(
        <div>
            <PageBanner />
            <Form />
            {/* <Meeting /> */}
            {/* <ContactInfo /> */}
            {/* <Address /> */}
            {/* <LocationMap /> */}
        </div>
    )
}

export default Contact;