import React from "react";
import './banner.css'

// import upWorkImg from '../homePageAssets/upWork_white.webp';
import Video from './banner_video.mp4';


function Banner(){
    return(

        <div class="banner">
            
            {/* <video className="w-100" autoplay muted loop id="myVideo">
                <source src={Video} type="video/mp4" />
            </video> */}

            <div className="bg_overlay">
                <div className=" text_Cont">
                    <h1 className='banner_text text-light titleFont_style'>Welcome to Lead Venture, where innovation meets unparalleled marketing expertise.  </h1>
                    <p className='text text-light py-4'> Lead Venture's technology platforms power over 10,000 Insurance agents all across USA
                    </p>
                    <a href="https://calendly.com/ahsan-arshad-leadventures/30min" target="_blank" className="btn form_btn dark_btn">Schedule A Meeting<i class="fa-solid fa-arrow-right ms-2"></i> </a> 
                </div>
            </div>           
        </div>

    )
}

export default Banner;