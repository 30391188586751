import React from "react";

function Mission(){
    return(
        <>
            <div className="container about_sec  section_padding">
                <h2 className='banner_text titleFont_style py-3'>Who We Are</h2>
                <p className='text text_color'>
                    At Lead Ventures, we specialize in delivering pre-qualified, live transfer leads that connect insurance agents with ready-to-convert prospects, driving growth and success for your agency.
                </p>
            </div>

            <div className="light-bg about_sec section_padding">
                <div className="container">
                    <h2 className='banner_text titleFont_style py-3'>Our Story</h2>
                    <p className='text text_color'>
                        At Lead Ventures, we empower independent dealers to compete on par with the world’s most sophisticated retailers. Our portfolio features best-in-class technology brands across multiple verticals, setting industry standards and delivering exceptional customer experiences.
                    </p>
                    <p className='text text_color'>
                        Despite our rapid growth and industry leadership, we stay true to our roots, driven by a commitment to innovation and excellence. We continuously push the boundaries of what’s possible in dealership services, ensuring our clients thrive in an ever-changing market.                    </p>
                </div>
            </div>
        </>
    )
}

export default Mission;