import React from "react";
import PageBanner from "./servicesBanner/pageBanner";
import Services from "./services/services";


function ServicesPage(){
    return(
        <div>
            <PageBanner />
            <Services />   
        </div>
    )
}


export default ServicesPage;