import React from "react";
import './form.css'

import Meeting from '../assets/contact-removebg.png'



function Form(){
    return(
        <div className="section_padding container">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <span className='span_Titles' ><b>Know More</b></span>
                <div className="bar mt-2 rounded"></div>
                {/* <h2 className='main_heading titleFont_style'>Get In Touch With us</h2> */}
                <h2 className='banner_text text-blue titleFont_style'>Get In Touch With us</h2>

                <p className="text py-3">
                    Want to Get In Touch? We're here to help! <br/>
                    Whatever the reason, all you need to do is contact us 🙂
                </p>
            </div>

            <div className="row light-bg rounded">
                <div className="col-md-6">
                    <div className="row g-3 m-auto">

                        <div className="col-md-12">
                            <label className="fw-bold">Name</label>
                        </div>

                        <div class="col-md-6">
                            <input type="text" class="form-control inputs_form" placeholder="First name" />
                        </div>

                        <div class="col-md-6">
                            <input type="text" class="form-control inputs_form" placeholder="Last name" />
                        </div>
                        
                        <div className="col-md-12">
                            <label className="fw-bold">Email</label>
                        </div>

                        <div className="col-md-12">
                            <input type="email" class="form-control inputs_form" placeholder="Email" />
                        </div>

                        <div className="col-md-12">
                            <label className="fw-bold">Phone*</label>
                        </div>

                        <div className="col-md-12">
                            <input type="tel" class="form-control inputs_form" placeholder="Phone" />
                        </div>

                        <div className="col-md-12">
                            <label className="fw-bold" >Message</label>
                        </div>

                        <div className="col-md-12">
                            <textarea type="text" class="form-control inputs_form" placeholder="Message" />
                        </div>

                        <div className="col-md-12 mt-5">
                            <a href="https://calendly.com/ahsan-arshad-leadventures/30min" target="_blank" className="btn border_btn rounded">Schedule A Meeting<i class="fa-solid fa-arrow-right ms-2"></i> </a> 

                        </div>
                    </div>
                </div>

                <div className="col-md-6 d-flex align-items-center justify-content-end p-5">
                    <div className="meeting_img">
                        <img src={Meeting} className="w-100" alt="" />
                    </div>
                </div>
            </div>


            <div className="d-flex">
                <iframe class="Iframe" src="https://calendly.com/ahsan-arshad-leadventures/30min" target='_blank'  ></iframe>
            </div> 

        </div>
    )
}



export default Form;