import React from "react";
import Banner from "./banner/banner";
import ClientSection from "./clientsSection/clientSection";
import AboutSection from "./aboutSection/aboutSec";
import ServicesSection from "./servicesSection/servicesSections";
// import DealerSolution from "./dealerSolutions/dealer";



function Home(){
    return(
        <div>
            <Banner />
            <ClientSection />
            <AboutSection />
            {/* <DealerSolution /> */}
            <ServicesSection />

        </div>
    )
}

export default Home;