import React from "react";
 
import Qualified_Leads from '../images/qualified_leads-removebg.png';
import Live_transfer from '../images/liveTransfers.jpg';
import Appointment from '../images/appointment.jpg'

import './services.css'

function Services(){
    return(

                
        <div className="container section_padding text-center">

            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className='span_Titles' ><b>What We’re Offering</b></span>
                <div className="bar mt-2 rounded"></div>
                <h2 className='banner_text titleFont_style'>Services We’re Providing</h2>
            </div>
    
                {/* SERVICES */}
    
            <div className="py-5">

                <div className="row g-5 w-100 rounded-3">
                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                        <div className="s_imgs">
                            <img src={Qualified_Leads} className="w-100 boxShadow p-5" alt="" />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column justify-content-center">
                        <h2 className='sub_titles titleFont_style py-2 text-start'>Qualified Leads</h2>
                        <p className="text text-start">
                            Once we get started, our team will work with you to understand your specific qualifying criteria. From our experience, every agent has unique targets and preferences.                        </p>
                        <p className="text text-start">
                            Based on your criteria, we ensure that all the leads transferred to you are pre-qualified. When we transfer the call, we also make sure that all relevant information is shared with you through a dedicated portal provided by our team.
                        </p>
                    </div>
                </div>

                <div className="row g-5 w-100 my-5 rounded-3 home_service_row">
                    <div className="col-md-7 d-flex flex-column justify-content-center">
                        <h2 className='sub_titles titleFont_style py-2 text-start'>Live Transfers</h2>
                        <p className="text text-start">
                            Our Live Transfer Auto Leads program is tailored for agents who want to connect with warm, pre-qualified leads in real-time. Our team of highly trained telemarketers will transfer quotable leads directly to your agency, streamlining your sales process and boosting efficiency.
                        </p>
                        <p className="text text-start">
                            With our LiveLeads Connect program, we take it a step further. Our telemarketing team will contact your selected lead lists and transfer pre-qualified, quotable leads directly to your agency, ensuring you’re always connected to prospects ready to discuss their needs.
                        </p>
                    </div>

                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                        <div className="s_imgs border-0">
                            <img src={Live_transfer} className="w-100 boxShadow p-3" alt="" />
                        </div> 
                    </div>
                </div> 

                <div className="row g-5 w-100 rounded-3">
                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                        <div className="s_imgs border-0">
                            <img src={Appointment} className="w-100 boxShadow p-3" alt="" />
                        </div>
                    </div>

                    <div className="col-md-7 d-flex flex-column justify-content-center">
                        <h2 className='sub_titles titleFont_style py-2 text-start'>Appointment Settings</h2>
                        <p className="text text-start">
                            If you’re not looking for live call transfers, our team at Lead Ventures offers an alternative solution. We’ll qualify leads based on your specified criteria and schedule appointments with you for a later time.
                        </p>
                        <p className="text text-start">
                            With this service, you can connect with prospects at your convenience, turning those leads into policies on your own schedule.
                        </p>
                    </div>
                </div>

            </div>
    
        </div>
    )
}


export default Services;