import React from "react";
import PageBanner from "./pageBanner/pageBanner";
import Mission from "./mission/mission";
import Diff from "./diff/diff";

function All(){
    return(
        <div>
            <PageBanner />
            <Mission />
            <Diff />
        </div>
    )
}

export default All;