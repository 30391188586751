import React from "react";
import './header.css'
// import LogoImg from '../assets/logoWhite.webp';
import Logo from './images/fina-Leads-logo-1.webp'

import {Nav,Navbar,NavDropdown} from "react-bootstrap";

import { useEffect } from "react";

function Header(){

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let header = document.querySelector(".NavigationBar");
      let navBtn = document.querySelector(".navBtn-scroll")
      if (window.scrollY > 90) {
        header?.classList.add("scrolled");
        navBtn?.classList.add('navBtn-scroll')
      } else {
        header?.classList.remove("scrolled");
        navBtn?.classList.remove('navBtn-scroll')
      }
    });
  }, []);

  

    return(
        <div>
          <Navbar expand="lg" className="NavigationBar">
    
          <Navbar.Brand href="/" className="px-2">
            <div className="LogoBox margin"><img src={Logo} className='w-100' alt="" /></div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="nav_list">

            <Nav className="ms-auto">
            <Nav.Link className="menu" href="/">Home</Nav.Link>
            <Nav.Link className="menu" href="/aboutUs">About Us</Nav.Link>
            <Nav.Link className="menu" href="/services">Services</Nav.Link>
            <Nav.Link className="menu" href="/contact">CONTACT US</Nav.Link>
            
            {/* <Nav.Link className="" href="/pricing">Pricing</Nav.Link> */}
            
          </Nav>

          <form class="d-flex margin">
            <a href="https://calendly.com/ahsan-arshad-leadventures/30min" target="_blank" className="btn form_btn white_btn text-light">Schedule A Meeting<i class="fa-solid fa-arrow-right ms-2"></i> </a> 
          </form>

      </Navbar.Collapse>
        
          </Navbar>


        </div>
    )
}

export default Header;