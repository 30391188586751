import React from "react";
import './pageBanner.css'

function PageBanner(){
    return(  
         <div class="banner pageBanner">
            <div className="bg_overlay">
                <div className=" text_Cont d-flex flex-column justify-content-center align-items-center text-center">
                    {/* <h2 className='main_heading titleFont_style text-light'>Contact Us</h2> */}
                    <h2 className='banner_text text-light titleFont_style'>Contact Us</h2>

                    
                </div>
            </div>           
        </div>
    )
}

export default PageBanner;