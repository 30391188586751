import React from "react";
import './diff.css'

// import Check_Box from '../../homePage/homePageAssets/check-mark.png';
// import Dollar from '../../homePage/homePageAssets/dollar.png';
// import Settings from '../../homePage/homePageAssets/setting.png'


function Diff(){
    return(
        <div className="container">

            <div className="text-center AB_content_box section_padding">
                <h2 className='banner_text titleFont_style py-3'>What Makes Us Different</h2>
                <p className='text text_color'>
                    At Lead Ventures, we stand apart from other insurance lead providers by delivering 100% exclusive leads—never shared with multiple agencies. Each lead you receive is yours alone. Guaranteed.
                </p>
            </div>

            <div className="content">
                <h2 className='sub_titles heading_bg d-flex align-items-center'>Highest Quality <i class="fa-solid fa-check iconSize ms-3 "></i></h2>
                <p className='pt-3 text text_color'>
                    Get hot, fresh leads delivered within 24 hours of signing up. Every lead meets our strict criteria, being double-verified, exclusive, and geographically targeted. Additionally, all calls are recorded to guarantee top-tier quality.
                </p>
            </div>

            <div className="content my-5">
                <h2 className='sub_titles heading_bg d-flex align-items-center'>Best Value <i class="fa-solid fa-dollar-sign iconSize ms-3 "></i></h2>
                <p className='pt-3 text text_color'>
                    By partnering with leading insurance carriers, we deliver high-quality, exclusive leads to agents at competitive and affordable prices.                </p>
            </div>

            <div className="content my-5">
                <h2 className='sub_titles heading_bg d-flex align-items-center'>Winning Service <i class="fa-solid fa-gears iconSize ms-3 "></i></h2>
                <p className='pt-3 text text_color'>
                    Each lead is double-verified to meet the high standards of Lead Ventures before being sent to your agency. With seamless integrations into top CRM providers, LeadLogix Leads ensures a smooth transition. Plus, our award-winning service team is always ready to support you every step of the way.                </p>
            </div>

            <div className="content my-5">
                <h2 className='sub_titles heading_bg d-flex align-items-center'>Industry-Leading Technology <i class="fa-solid fa-laptop-code iconSize ms-3"></i></h2>
                <p className='pt-3 text text_color'>
                    At Lead Ventures, we leverage advanced technology to deliver unparalleled lead generation solutions. Our state-of-the-art platform is designed to optimize efficiency and performance, ensuring that every lead is pre-qualified and ready for conversion. With seamless integration and intuitive functionality, our technology empowers insurance agents to connect with prospects faster and more effectively, setting a new standard in the industry.                </p>
            </div>

            <div className="content my-5">
                <h2 className='sub_titles heading_bg d-flex align-items-center'>Expert Support and Consultation <i class="fa-solid fa-phone iconSize ms-3"></i></h2>
                <p className='pt-3 text text_color'>
                    At Lead Ventures, our team of industry experts is committed to offering exceptional support and data-driven guidance based on proven best practices. We take the time to understand each client’s unique challenges and work collaboratively to deliver customized solutions that drive tangible results.                </p>
            </div>


        </div>
    )
}



export default Diff;