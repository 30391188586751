import React from "react";
import ClientCarousel from "./clientCarousel";




function ClientSection(){
    return(
        <div className="container-fluid bg-blue">
            <ClientCarousel />
        </div>
    )
}

export default ClientSection;