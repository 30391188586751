import React from "react";
import './footer.css'
// import FooterLogo from '../assets/logoWhite.webp';
// import upWorkImg from '../assets/upWork_white.webp'

// import Logo from '../homePage/homePageAssets/logo.png'
import Logo from './assets/fina-Leads-logo-1.png'


function Footer(){
    return(

     <div className="bg-blue">

        {/* <div className="row w-100">
            <div className="col-lg-5 col-md-8 m-auto">
                <div className="footer_callBox">
                    <div className="text-center">
                        <h2 className='footerBox_title'>10 Years Providing Qualified Leads And We Are Just Getting Started</h2>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-wrap pt-3">
                        <a href="https://calendly.com/hamzaaftab1987/30min" target="_blank" className="btn gradient_Bg form_btn text-light rounded">Schedule A Meeting<i class="fa-solid fa-arrow-right ms-2"></i> </a> 
                    </div>
                </div>
            </div>
        </div> */}

      
            <div className="container footer-Section">
                <div className="row w-100">

                    <div className="col-md-3">
                        <div className="footer_Logo" ><img src={Logo} className='w-100' alt="" /></div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer_navigation m-auto">
                        <h2 className="sub_titles text-light pb-4">About Us</h2>
                            <a className="nav-link" href="/">  
                                <span className="text text-light fontSize_sm"> Home</span>
                            </a>
                            <a className="nav-link" href="/aboutUs">  
                                <span className="text text-light fontSize_sm"> About Us</span>
                            </a>
                            <a className="nav-link" href="/services">  
                                <span className="text text-light fontSize_sm"> Services</span>
                            </a>
                            <a className="nav-link" href="/contact">  
                                <span className="text text-light fontSize_sm"> Contact</span>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-3">

                        <div className="">
                            <h2 className="sub_titles text-light pb-4">Services</h2>
                            <a className="nav-link" href="/services">  
                                <span className="text text-light fontSize_sm"> Qualified Leads</span>
                            </a>
                            <a href="/services" className="nav-link">
                                <span className="text text-light fontSize_sm"> Live Transfers</span>
                            </a>
                            <a className="nav-link" href="/services">
                                <span className="text text-light fontSize_sm">Appointment Settings</span>
                            </a>
                        </div>

                    </div>

                    <div className="col-md-3">

                        <div className="">
                            <h2 className="sub_titles text-light pb-4">Head Office</h2>
                            <a className="nav-link" href="">  
                                <span className="text text-light fontSize_sm"> <i class="fa-solid fa-location-dot"></i> Karachi, Pakistan</span>
                            </a>
                            {/* <a href="tel:+92-42-35833800" className="nav-link">
                                <span className="text text-light fontSize_sm"> <i class="fa-solid fa-phone"></i> +92 316 5656706</span>
                            </a> */}
                            <a className="nav-link" href="mailto:info@leadventures.net">
                                <span className="text text-light fontSize_sm"> <i class="fa-regular fa-envelope"></i> info@leadventures.net</span>
                            </a>
                        </div>

                    </div>

                   
                    
                </div>
            </div>

            <div className="copyRightBar text-center pb-4 bg-blue">
                <p className="text-light text p-0 m-0">© Copyright 2025 – Lead Ventures</p>
            </div>


                
    
     </div>
    )
}

export default Footer;